import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { EnvironmentInterface } from '@environments/environment.interface'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject } from 'rxjs'
import { AppService } from '../../../../app.service'
import { NavItem } from './navigation.model'

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  navItems: NavItem[] = []

  env = environment as EnvironmentInterface

  navigationToggled$ = new BehaviorSubject<boolean>(localStorage.getItem('navigationToggled') == 'true')

  navigationAnimationRunning$ = new BehaviorSubject<boolean>(false)

  navigationAnimationFinished$ = new BehaviorSubject<boolean>(false)

  navigationAnimationStarted$ = new BehaviorSubject<boolean>(false)

  selectedNavigationItem$ = new BehaviorSubject<string>('')

  profileMenuToggled$ = new BehaviorSubject<boolean>(false)

  organisationMenuToggled$ = new BehaviorSubject<boolean>(false)

  createOrganisationVisible$ = new BehaviorSubject<boolean>(false)

  constructor(
    private app: AppService,
    private proficloud: ProficloudService
  ) {
    // Initialize the navigation with all existing/possible elements
    this.initializeNavigationItems()

    // Filter the navigation based on the given whitelabel
    this.filterNavigationItems()

    this.navigationAnimationStarted$.subscribe({
      next: (started) => {
        if (started) {
          this.navigationAnimationRunning$.next(true)
        }
      },
    })

    this.navigationAnimationFinished$.subscribe({
      next: (finished) => {
        if (finished) {
          this.navigationAnimationRunning$.next(false)
        }
      },
    })
  }

  toggleNavigation(expanded?: boolean) {
    if (expanded !== undefined) {
      this.navigationToggled$.next(expanded)
    } else {
      this.navigationToggled$.next(!this.navigationToggled$.value)
    }
    localStorage.setItem('navigationToggled', String(this.navigationToggled$.value))
  }

  toggleProfileMenu() {
    this.profileMenuToggled$.next(!this.profileMenuToggled$.value)
  }
  toggleOrgMenu() {
    this.organisationMenuToggled$.next(!this.organisationMenuToggled$.value)
  }
  showProfileMenu() {
    this.profileMenuToggled$.next(true)
  }

  hideProfileMenu() {
    this.profileMenuToggled$.next(false)
  }
  hideOrgMenu() {
    this.organisationMenuToggled$.next(false)
  }

  showCreateOrganisation() {
    this.createOrganisationVisible$.next(true)
  }

  hideCreateOrganisation() {
    this.createOrganisationVisible$.next(false)
  }

  selectNavigationItem(navItemName: string) {
    this.selectedNavigationItem$.next(navItemName)
  }

  private initializeNavigationItems = () => {
    this.navItems = [
      {
        name: 'NAV.DMS',
        id: 'device-management-service',
        showChildrenAt: 'device-management-service',
        icon: 'device',
        hover: false,
        routerLink: '/services/device-management/list',
        analyticsTag: 'device-management-service-navigation',
        children: [
          {
            name: 'Devices',
            id: 'navigation-device-management-service-devices',
            routerLink: '/services/device-management/list',
            hideInProduction: false,
            showInProductionFlag: 'feature_flag_dms',
            analyticsTag: 'dms-device-overview',
          },
          {
            name: 'Device Groups',
            id: 'navigation-device-management-service-groups',
            routerLink: '/services/device-management/group',
            hideInProduction: false,
            showInProductionFlag: 'feature_flag_dms',
            analyticsTag: 'dms-device-groups',
          },
          {
            name: 'Alerting',
            id: 'navigation-device-management-service-alerting',
            routerLink: '/services/device-management/alerting',
            hideInProduction: false,
            showInProductionFlag: 'feature_flag_dms',
            analyticsTag: 'dms-alerting',
          },
        ],
      },
      {
        name: 'NAV.USER_MANAGEMENT',
        id: 'user-management-service',
        icon: 'userManagement',
        hover: false,
        routerLink: '/services/user-management-service/users',
        hideInProduction: false,
        adminPermission: true,
        showChildrenAt: 'user-management-service',
        children: [
          {
            name: 'Users',
            id: 'navigation-user-management-service-users',
            routerLink: '/services/user-management-service/users',
          },
          {
            name: 'Roles',
            id: 'navigation-user-management-service-user-roles',
            routerLink: '/services/user-management-service/roles',
          },
        ],
      },
      {
        name: 'NAV.STORE',
        id: 'store-service',
        icon: 'cart',
        hover: false,
        routerLink: '/services/store',
        hideInProduction: false,
      },
      {
        name: 'NAV.EMMA',
        id: 'energy-management-service',
        icon: 'emma',
        hover: false,
        routerLink: '/services/energy-management/dashboards',
        hideInProduction: false,
        showChildrenAt: 'energy-management-service',
        children: [
          {
            name: 'Dashboards',
            id: 'navigation-energy-management-service-dashboards',
            routerLink: '/services/energy-management/dashboards',
          },
          {
            name: 'Reports',
            id: 'navigation-energy-management-service-reports',
            routerLink: '/services/energy-management/reports',
          },
          {
            name: 'Alerts',
            id: 'navigation-energy-management-service-alerts',
            routerLink: '/services/energy-management/alerts',
          },
          {
            name: 'Simulator',
            id: 'navigation-energy-management-service-simulator',
            routerLink: '/services/energy-management/simulator',
            hideInProduction: true,
            showInProductionFlag: 'emma_simulator',
          },
        ],
      },
      {
        name: 'NAV.E_CHARGING',
        id: 'e-charging-service',
        icon: 'eCharging',
        hover: false,
        routerLink: '/services/charge-repay-service',
        showChildrenAt: 'e-charging-service',
        hideInProduction: true,
        showInProductionFlag: 'feature_flag_emob',
        children: [
          {
            name: 'Charges',
            id: 'charge-repay-charges',
            routerLink: '/services/charge-repay-service/home',
            hideInProduction: true,
            showInProductionFlag: 'feature_flag_emob',
          },
          {
            name: 'Settings',
            id: 'charge-repay-settings',
            routerLink: '/services/charge-repay-service/admin',
            hideInProduction: true,
            showInProductionFlag: 'feature_flag_emob',
          },
          {
            name: 'Exports',
            id: 'charge-repay-exports',
            routerLink: '/services/charge-repay-service/exports',
            hideInProduction: true,
            showInProductionFlag: 'feature_flag_emob',
          },
        ],
      },
      {
        name: 'NAV.OCPP',
        id: 'ocpp-proxy',
        icon: 'ocppProxy',
        hover: false,
        routerLink: '/services/ocpp-proxy/charge-points',
        showChildrenAt: 'ocpp-proxy',
        hideInProduction: true,
        showInProductionFlag: 'feature_flag_emob',
        children: [
          {
            name: 'Charge Points',
            id: 'ocpp-proxy-charge-point-config',
            routerLink: '/services/ocpp-proxy/charge-points',
            hideInProduction: true,
            showInProductionFlag: 'feature_flag_emob',
          },
          {
            name: 'Integration',
            id: 'ocpp-proxy-overview',
            routerLink: '/services/ocpp-proxy/overview',
            hideInProduction: true,
            showInProductionFlag: 'feature_flag_emob',
          },
        ],
      },
      {
        name: 'NAV.TSD',
        id: 'time-series-data-service',
        icon: 'tsd',
        hover: false,
        link: this.proficloud.grafanaLinks[this.app.environment.backend],
      },
      {
        name: 'NAV.IA',
        id: 'impulse-analytics-service',
        icon: 'impulseAnalytics',
        hover: false,
        link: this.proficloud.impulseAnalyticsLinks[this.app.environment.backend],
      },
      {
        name: 'NAV.ADMIN_SERVICE',
        id: 'admin-service',
        showChildrenAt: 'admin-service',
        icon: 'fingerprint',
        hover: false,
        routerLink: '/services/admin-service/uuids',
        hideInProduction: true,
        showInProductionFlag: 'AdminService',
        children: [
          {
            name: 'UUIDs & Devices',
            id: 'navigation-admin-service-uuids',
            routerLink: '/services/admin-service/uuids',
            hideInProduction: true,
            showInProductionFlag: 'AdminService',
          },
          {
            name: 'Device Types',
            id: 'navigation-admin-service-device-types',
            routerLink: '/services/admin-service/device-types',
            hideInProduction: true,
            showInProductionFlag: 'AdminService',
          },
          {
            name: 'Firmware Versions',
            id: 'navigation-admin-service-firmware-versions',
            routerLink: '/services/admin-service/firmware-versions',
            hideInProduction: true,
            showInProductionFlag: 'AdminService',
          },
        ],
      },
    ]
  }

  private filterNavigationItems() {
    this.env.serviceBlacklist.map((blService) => {
      this.navItems = this.navItems.filter((s) => s.id !== blService)
    })
  }
}
