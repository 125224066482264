import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { watched } from '../../../../shared/decorators/watch-stream-decorator'
import { ClassDoc } from '../../../../shared/services/tracing/tracing.interfaces'
import { NotificationService } from '../../../services/notification.service'
import { AlertRule, EmmaAlertMessage, SystemNotification } from '../../../services/proficloud.interfaces'
import { EmmaHttpService } from '../backend/emma-http.service'
import { EmmaStore } from '../stores/emma.store'
import { EmmaService } from './emma.service'

@Injectable({
  providedIn: 'root',
})
export class EmmaNotificationsService {
  classDoc: ClassDoc = {
    name: 'NotificationService',
    location: '/@emmaServices/emma-notification.service.ts',
  }

  @watched({
    when: `After alert rules have been fetced`,
  })
  allAlertMessagesDismissed$ = new Subject<boolean>()

  alertRules: AlertRule[] = []

  constructor(
    private emmaService: EmmaService,
    private emmaStore: EmmaStore,
    private emmaHttp: EmmaHttpService,
    private notificationService: NotificationService
  ) {
    // Keep an internal list of alert rules for convenience in the template
    this.emmaStore.alertMessagesAndRules$.subscribe((res) => {
      this.alertRules = res.rules
      this.alertRules.forEach((ar) => {
        // TODO: Only do on inital fetch (move to store perhaps)
        ar.receivedAlertCount = 0
      })

      res.messages.forEach((message) => {
        this.processMessage(message)
      })

      this.notificationService.setNotDismissed()
    })

    // Consume alert messages as they arrive
    this.emmaStore.alertMessages$.subscribe((messages) => {
      if (Array.isArray(messages)) {
        messages.forEach((message) => {
          this.processMessage(message)
        })
      } else {
        const message = messages
        this.processMessage(message)
        if (message.parentRule) {
          message.parentRule.unacknowledgedMessages++
        }
      }
    })

    // Take care of dismissing all the EMMA alerts (might want to move this into the notification service)
    this.notificationService.dismissAllRequired$.subscribe(() => {
      this.emmaHttp.dismissAllEmmaNotifications().subscribe({
        next: (v) => {
          this.notificationService.allNotifications
            .filter((n) => n.serviceNotification.responsible === 'emma')
            .forEach((n) => {
              n.dismissed = true
              ;(n.serviceNotification as EmmaAlertMessage).acknowledged = true
            })

          // Set all parent rule unacknowledge counts to be zero so we don't have to refresh them
          this.alertRules.forEach((ar) => {
            ar.unacknowledgedMessages = 0
          })

          this.allAlertMessagesDismissed$.next(true)
        },
        error: (v) => {
          console.log('error', v)
        },
      })
    })
  }

  private processMessage(message: EmmaAlertMessage) {
    // Assign parent
    this.assignParent(message)
    message.responsible = 'emma'
    // TODO: Broken since the queryables have not been fetched yet
    message.queryableName = this.emmaService.getQueryableName(message.metricId)

    // We do this here and not in the notification service because we need to have the parent set
    if (message.parentRule) {
      const newAlert: SystemNotification = {
        serviceNotification: message,
        dismissed: false,
        persisted: true,
        received: new Date(message.createdAt),
      }

      // TODO: Perhaps move this into a pipe on the service or sth
      this.notificationService.allNotifications.unshift(newAlert)
      this.notificationService.setNotDismissed()
    }
  }

  public assignParent(alert: EmmaAlertMessage) {
    alert.parentRule = undefined
    const parent = this.alertRules.find((ar) => ar.id === alert.alertRuleID)
    if (parent) {
      parent.receivedAlertCount += 1
      alert.parentRule = parent
    }
  }

  public assignAlertParentRulesAndCleanup() {
    // Set priorities and parent rules
    this.notificationService.allNotifications.forEach((notification) => {
      if (notification.serviceNotification.responsible === 'emma') {
        const alert = notification.serviceNotification
        this.assignParent(alert as EmmaAlertMessage)
      }
    })

    // Note: I think the current policy is to show all old alerts even if the parent has been deleted but this needs to be confirmeds
    // // Remove EMMA notifications who's alerts don't have a parent rule (might have been deleted)
    // this.notificationService.allNotifications = this.notificationService.allNotifications.filter((n) => {
    //   return (
    //     n.serviceNotification.responsible !== 'emma' ||
    //     (n.serviceNotification.responsible === 'emma' && !!(n.serviceNotification as EmmaAlertMessage).parentRule)
    //   )
    // })
  }

  public removeRuleWithID(id: string) {
    const rule = this.alertRules.find((r) => r.id === id)
    if (rule) {
      const i = this.alertRules.indexOf(rule)
      this.alertRules.splice(i, 1)
    }
  }
}
