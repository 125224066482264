import { Component } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { ClassDoc } from 'src/app/modules/shared/services/tracing/tracing.interfaces'

@Component({
  selector: 'app-energy-management',
  templateUrl: './energy-management.component.html',
  styleUrls: ['./energy-management.component.scss'],
})
export class EnergyManagementComponent {
  classDoc: ClassDoc = {
    name: 'EnergyManagementComponent',
    location: '/src/app/modules/proficloud/components/energy-management/energy-management.component.ts',
  }

  constructor(
    public ui: UiService,
    public proficloud: ProficloudService
  ) {}
}
